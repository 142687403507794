import axios from 'axios';
import resolveResponse from 'contentful-resolve-response'
import { cms } from '@/config/cms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asyncGet = (): Promise<any> => {
    return new Promise(resolve => {
        axios.get(cms.apiUrl)
            .then(response => resolve(resolveResponse(response.data)[0].fields))
            .catch(error => resolve(error))
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contentfulData = async (): Promise<any> => {
    const response = await asyncGet();
    return response;
}
