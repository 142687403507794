




































import { contentfulHtml, slug } from "@/utils";
export default {
  props: {
    data: Object,
  },
  methods: {
    contentfulHtml: contentfulHtml,
    slug: slug,
  },
}
