















import Nav from "./components/Nav.vue";
import Welcome from "./components/Sections/Welcome.vue"; // Just for reference
import PlainSection from "./components/Sections/PlainSection.vue";
import BackToTop from "./components/BackToTop.vue";
import store from './store';

export default {
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cmsData() {
      return store.state.data;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    tempComponents() {
      return [
        //{"type": "resume"},
        //{"type": "skills"},
        //{"type": "portfolio"},
        //{"type": "facts"},
        //{"type": "testimonials"},
        { type: "blog" },
        { type: "contact" },
        { type: "footer-1" },
        { type: "scroll-up" },
      ];
    },
  },
  components: {
    Nav,
    PlainSection,
    Welcome, // Just for reference
    BackToTop,
  },
};
