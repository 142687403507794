import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import store from '@/store';
import { contentfulData } from '@/utils'

Vue.use(Vuex)
Vue.config.productionTip = false

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

contentfulData().then(data => {
  if (data.seo) { 
    store.commit('set', data);
  } else {
    throw data;
  }
}).catch(err => {
  console.log('cmsError', err);
  store.commit('set', window.content);
}).finally(() => {
  new Vue({
    store,
    render: h => h(App),
  }).$mount('#app')
})
