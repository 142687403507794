













import { contentfulHtml, slug } from "@/utils";
export default {
  props: {
    data: Object,
    index: Number,
  },
  methods: {
    alternateClass(): string {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (this as any).index % 2 ? 'even' : 'odd';
    },
    contentfulHtml: contentfulHtml,
    slug: slug,
  },
};
